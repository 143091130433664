export const FAQData = [
    {
        question: "What does STEAM stand for?",
        answer: "STEAM stands for Science, Technology, Engineering, Art & Design, Maths",
    },
    {
        question: "How do I learn more about GIS?",
        answer: "Follow our instagram @girlsinsteamorg",
    },
    {
        question: "How do I join the GIS team?",
        answer: "Recruitment for the executive team happens every year in January. Stay tuned!",
    }
];